<template>
  <div class="card card-blue-100 mb-2">
    <div v-if="tournament.matched && matchesText()" class="card-header-sm w-full">
      {{ matchesText() }}
    </div>
    <div class="card-body flex flex-col pt-1 pb-2">
      <a :href="'/tournaments/' + tournament.slug" class="text-blue-600 text-xl sm:text-3xl mr-2 cursor-pointer">
        {{ tournament.name }}
      </a>
      <div v-if="tournament.club">
        <a :href="'/clubs/' + tournament.club.slug" class="text-blue-500 sm:text-xl cursor-pointer">
          hosted by {{ tournament.club.name }}
        </a>
      </div>
      <div class="flex mt-4 md:mt-8">
        <div class="flex flex-col w-full md:w-1/2">
          
          <div>Sport: {{ tournament.sport.name }}</div>
          <div>Tournament Dates: {{ dateString }}</div>
          <span v-if="location">{{ location }}</span>
          <div class="flex">
            <a v-if="status === 'open' && space"
               :href="'/tournaments/' + tournament.slug + '#/register'"
               class="btn-blue-600 btn mt-2 w-36 p-1 mr-2">Register</a>
            <a v-if="started"
               :href="'/tournaments/' + tournament.slug + '#/results' + (search ? '?search=' + search : '')"
               class="btn-blue-600 btn mt-2 w-36 p-1 mr-2">View Results</a>
          </div>
        </div>
        
        <div class="hidden md:flex w-1/2 h-center">
          <div v-if="tournament.description" class="h-40 overflow-y-auto">
          <pre style="font-family: 'Roboto', sans-serif;"
               class="text-gray-600 px-4 whitespace-pre-wrap">{{ tournament.description }}</pre>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TournamentListItem",
  props: ['tournament', 'search'],
  data() {
    return {
      showDescription: this.tournament.description && this.tournament.description.length > 200,
    }
  },
  computed: {
    dateString() {
      const start = new Date(this.tournament.start_time);
      let momentStart = moment(start);
      const end = new Date(this.tournament.end_time);
      let momentEnd = moment(end);
      if (momentStart.date() === momentEnd.date()) {
        return momentStart.format('MMM D, YYYY');
      }
      let dateString = momentStart.format('MMM D');
      if (momentStart.month() === momentEnd.month()) {
        return `${dateString}${momentEnd.format('-D, YYYY')}`;
      } else if (momentStart.year() === momentEnd.year()) {
        return `${dateString}${momentEnd.format(' - MMM D, YYYY')}`;
      }
      return `${momentStart.format('MMM D')}${momentEnd.format(' - MMM D, YYYY')}`;
    },
    started() {
      let now = new Date();
      let time = new Date(this.tournament.start_time);
      return moment(time).isBefore(now);
    },
    start() {
      let time = new Date(this.tournament.start_time);
      return moment(time).format('DD MMM');
    },
    end() {
      let time = new Date(this.tournament.end_time);
      return moment(time).format('DD MMM YYYY');
    },
    location() {
      let loc = '';
      if (this.tournament.address) {
        if (this.tournament.address.city) {
          loc += this.tournament.address.city;
          if (this.tournament.address.state) {
            loc += ' - ';
          }
        }
        loc += this.tournament.address.state ? this.tournament.address.state : '';
      }
      return loc;
    },
    status() {
      let now = new Date();
      let status = '';
      let closeTime = new Date(this.tournament.end_time);
      let close = moment(closeTime).add(1, 'days');
      if (close < now) {
        status = 'closed';
      } else {
        status = 'open';
      }
      return status;
    },
    space() {
      return this.tournament.competitors_count < this.tournament.max_competitors
    }
  },
  methods: {
    matchesText() {
      let text = '';
      if (this.tournament.matched.archers || this.tournament.matched.teams) {
        text = 'Matches ';
        if (this.tournament.matched.archers) {
          if (this.tournament.matched.archers.length < 5) {
            text += (this.tournament.matched.archers.length > 1 ? 'competitors' : 'competitor') + ': ';
            text += this.tournament.matched.archers.join(', ');
          } else {
            text += this.tournament.matched.archers.length + ' competitors with a similar name';
          }
          if (this.tournament.matched.teams) {
            text += ', and ';
          }
        }
        if (this.tournament.matched.teams) {
          if (this.tournament.matched.teams.length < 5) {
            text += (this.tournament.matched.teams.length > 1 ? 'teams' : 'team') + ': ';
            text += this.tournament.matched.teams.join(', ') + '. ';
          } else {
            text += this.tournament.matched.teams.length + ' teams with a similar name.';
          }
        }
      }
      return text;
    }
  }
}
</script>

<style scoped>
.largeDescription {
  overflow: hidden;
  max-height: 100px;
}
</style>
