<template>
  <tr>
    <td class="whitespace-nowrap" @click="editOption('first_name')">
      <transition name="fade">
        <span v-show="loading.indexOf('value_first_name') === -1">
          {{ competitor.first_name }}
          <span @click.stop v-if="editing === 'first_name'"><br>
            <input type="text" v-model="competitor.first_name" name="first_name"
                   @blur="updateCompetitor('first_name')">
          </span>
        </span>
      </transition>
    </td>
    <td class="whitespace-nowrap" @click="editOption('last_name')">
      <transition name="fade">
        <span v-show="loading.indexOf('value_last_name') === -1">
          {{ competitor.last_name }}
          <span @click.stop v-if="editing === 'last_name'"><br>
            <input type="text" v-model="competitor.last_name" name="last_name"
                   @blur="updateCompetitor('last_name')">
          </span>
        </span>
      </transition>
    </td>
    <td v-if="!isRound">
      <div class="flex flex-col">
        <div v-if="competitor.withdrew" @click="withdrewClicked" class="btn-xs btn-gray-400 mb-1">
          withdrew
        </div>
        <div v-if="!competitor.paid" @click="paidClicked" class="btn-xs btn-blue-600 mb-1">unpaid</div>
        <div v-if="competitor.wait_list" @click="waitlistedClicked" class="btn-xs btn-gray-600 mb-1">
          wait listed
        </div>
        <div v-if="!competitor.withdrew && competitor.paid && !competitor.wait_list"
             class="btn-xs btn-blue-400 mb-1" @click="unpaidClicked">
          {{ competitor.paid_reason ? competitor.paid_reason : 'paid' }}
        </div>
      </div>
    </td>
    <td v-if="!isRound" @click="editOption('email')">{{ competitor.email }}
      <span @click.stop v-if="editing === 'email'"><br>
        <input type="text" v-model="competitor.email" name="email" @blur="updateCompetitor('email')">
      </span>
    </td>
    <!--    <td v-if="!isRound && tournament.line_times.length">-->
    <!--      {{ requestedTime }}-->
    <!--    </td>-->
    <td v-if="!isRound && tournament.line_times.length > 1" @click="editOption('REQUESTED_LINE_TIME')"
        style="white-space: nowrap;">
      <transition name="fade">
        <span v-show="loading.indexOf('requested_line_time') === -1" id="requested_line_time">
          <span v-if="requestedTime">
            {{ requestedTime }} </span>
          <a @click.prevent href="" v-else> none selected </a>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'REQUESTED_LINE_TIME'">
        <br>
        <select class="custom-select" v-model="competitor.line_time" @blur="clearEditing"
                @change="updateCompetitor('requested_line_time')">
          <option v-for="line_time in tournament.rounds[0].line_times"
                  :selected="line_time.id === requestedTime.id"
                  :value="line_time.id">
            {{ line_time.time }}
          </option>
        </select>
      </span>
    </td>
    <td v-if="!isRound" v-for="extra in tournament.payments.pricing_extras ?? []">
      {{ pricingExtras(extra) }}
    </td>
    <td @click="detail.type === 'image' ? showImage(competitorDetail[detail.option]) :editOption(detail.option)"
        v-for="detail in validFormHeaders"
        :style="detail.type === 'textarea'?'white-space:auto;':''">
      <transition name="fade">
        <span v-show="loading.indexOf('value_' + detail.id) === -1">
          <img v-if="detail.type === 'image'" width="50" height="50" class="mx-auto cursor-pointer"
               :src="competitorDetail[detail.option]">
          <span v-else>
            <span v-show="competitorDetail[detail.option] && competitorDetail[detail.option].length">
              {{
                detail.type === 'checkbox' ? competitorDetail[detail.option].join(', ') :
                  competitorDetail[detail.option]
              }}
            </span>
            <a @click.prevent href=""
               v-show="!(competitorDetail[detail.option] && competitorDetail[detail.option].length)"> none
              selected </a>
          </span>
        </span>
      </transition>
      <span @click.stop v-if="editing === detail.option">
        <br>
        <span v-if="detail.multiple">
          <select class="custom-select" :multiple="detail.type === 'checkbox'"
                  v-model="competitorDetail[detail.option]"
                  @change="detail.type === 'checkbox'?'':updateCompetitor(detail.id)"
                  @blur="detail.type === 'checkbox'?updateCompetitor(detail.id):''">
            <option v-for="option in detail.multiple"
                    :selected="isSelected(detail, option.label)">
              {{ option.label }}
            </option>
            <option :value="null">none</option>
          </select>
        </span>
        <span v-else-if="detail.type === 'textarea'" v-show="editing === detail.option">
          <br>
          <textarea :name="detail.option" v-model="competitorDetail[detail.option]"
                    @blur="updateCompetitor(detail.id)">{{competitorDetail[detail.option]}}</textarea>
        </span>
        <span v-else v-show="editing === detail.option">
          <input type="text" v-model="competitorDetail[detail.option]" :name="detail.option"
                 @blur="updateCompetitor(detail.id)">
        </span>
      </span>
    </td>
    <td @click="editOption('TARGET')" v-if="tournament.targets.length > 1">
      <transition name="fade">
        <span v-show="loading.indexOf('value_target') === -1">
          <span v-if="competitor.target_id"> {{ getTargetName() }} </span>
          <a @click.prevent href="" v-if="!competitor.target_id"> none selected </a>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'TARGET'">
        <br>
        <select class="custom-select" v-model="competitor.target_id" @blur="clearEditing"
                @change="updateCompetitor('target')">
          <option v-for="target in tournament.targets"
                  :selected="target.id === competitor.target_id"
                  :value="target.id">
            {{ target.name }}
          </option>
          <option :value="null">none</option>
        </select>
      </span>
    </td>
    <td v-if="!isRound" class="text-center">
      {{ createdAt(competitor) }}
    </td>
    <td @click.prevent="deleteCompetitor()" v-if="!isRound" class="text-center">
      <span>
        <i class="fas fa-trash-alt"></i>
      </span>
    </td>
    <!-- Rework Teams in the future       <td @click="editOption('TEAM')" v-if="!isRound">-->
    <!--            <transition name="fade">-->
    <!--                <span v-show="loading.indexOf('value_team') === -1">-->
    <!--                    <span v-if="competitor.teams.length && competitor.teams[0]"> {{competitor.teams[0].name}} </span>-->
    <!--                    <a @click.prevent href="" v-else> none selected </a>-->
    <!--                </span>-->
    <!--            </transition>-->
    <!--            <span @click.stop v-show="editing === 'TEAM'">-->
    <!--                <br>-->
    <!--                <select class="custom-select" v-model="competitor.teams[0]" @blur="clearEditing"-->
    <!--                        @change="updateCompetitor('team')">-->
    <!--                    <option v-for="team in tournament.teams"-->
    <!--                            :selected="competitor.teams[0] ? team.id === competitor.teams[0].id : null"-->
    <!--                            :value="team">-->
    <!--                        {{team.name}}-->
    <!--                    </option>-->
    <!--                    <option :value="null">none</option>-->
    <!--                </select>-->
    <!--            </span>-->
    
    <!--        </td>-->
    <td @click="editOption('LINE_TIME')" v-if="isRound && competitor.assignments[round.id]"
        style="white-space: nowrap;">
      <transition name="fade">
        <span v-show="loading.indexOf('value_line_time') === -1" id="value_line_time">
          <span v-if="competitor.assignments[round.id].line_time">
            {{ getLineTimeFromId(competitor.assignments[round.id].line_time) }} </span>
          <a @click.prevent href="" v-else> none selected </a>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'LINE_TIME'">
        <br>
        <select class="custom-select" v-model="competitor.assignments[round.id].line_time" @blur="clearEditing"
                @change="updateCompetitor('line_time')">
          <option v-for="line_time in round.line_times"
                  :selected="line_time.id === competitor.line_time"
                  :value="line_time.id">
            {{ line_time.time }}
          </option>
          <option :value="null">none</option>
        </select>
      </span>
    </td>
    <td v-else-if="isRound" class="whitespace-nowrap">
      Not Approved
    </td>
    <td @click="editOption('LOCATION')" v-if="Object.keys(round).length !== 0 && competitor.assignments[round.id]">
      <transition name="fade">
        <span v-show="loading.indexOf('value_location') === -1" id="value_location">
          <span v-if="competitor.assignments[round.id].location">
            {{ competitor.assignments[round.id].location }} </span>
          <span v-else-if="!competitor.assignments[round.id].line_time">No Line Time</span>
          <a @click.prevent href="" v-else> none selected </a>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'LOCATION'" v-if="competitor.assignments[round.id].line_time">
        <br>
        <select class="custom-select" v-model="competitor.assignments[round.id].location" @blur="clearEditing"
                @change="updateCompetitor('location')">
          <option v-for="location in round.locations"
                  :selected="location === competitor.location">
            {{ location.name }}
          </option>
          <option :value="null">none</option>
        </select>
      </span>
    </td>
    <td v-else-if="isRound" class="whitespace-nowrap">
      Not Approved
    </td>
    <td @click="editOption('BALE')" v-if="isRound && competitor.assignments[round.id]" style="min-width:80px"
        class="text-center">
      <transition name="fade">
        <span v-show="loading.indexOf('value_bale') === -1" id="value_bale">
          <span v-if="competitor.assignments[round.id].bale">
            {{ competitor.assignments[round.id].bale }}
          </span>
          <span v-else-if="!competitor.assignments[round.id].location">No Location</span>
          <a @click.prevent href="" v-else> none selected </a>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'BALE'" v-if="competitor.assignments[round.id].location">
        <br>
        <select :key="'change_bale_' + round.id + competitor.id"
                class="custom-select"
                v-model="competitor.assignments[round.id].bale" @blur="clearEditing"
                @change="updateCompetitor('bale')">
          <option v-for="b in baleOptions"
                  :selected="b === competitor.bale"
                  :value="b"
                  :key="'change_bale_' + round.id + competitor.id + b">
            {{ b }}
          </option>
          <option :value="null">none</option>
        </select>
      </span>
    </td>
    <td @click="editOption('POSITION')" v-if="isRound && competitor.assignments[round.id]" style="min-width:80px"
        class="text-center">
      <transition name="fade">
        <span v-show="loading.indexOf('value_position') === -1" id="value_position">
          <span v-if="competitor.assignments[round.id].position">
            {{ competitor.assignments[round.id].position }}
          </span>
          <span v-else-if="!competitor.assignments[round.id].bale">No Bale</span>
          <a @click.prevent href="" v-else> none selected </a>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'POSITION'" v-if="competitor.assignments[round.id].bale">
        <br>
        <select :key="'change_position_' + round.id + competitor.id"
                class="custom-select"
                v-model="competitor.assignments[round.id].position" @blur="clearEditing"
                @change="updateCompetitor('position')">
          <option v-for="p in positionOptions"
                  :selected="p === competitor.position"
                  :value="p"
                  :key="'change_position_' + round.id + competitor.id + p">
            {{ p }}
          </option>
          <option :value="null">none</option>
        </select>
      </span>
    </td>
    <td v-else-if="isRound" class="whitespace-nowrap">
      Not Approved
    </td>
    <td @click="editOption('APPROVE')" v-if="isRound && competitor.assignments[round.id]" class="text-center">
      <transition name="fade">
        <span v-show="loading.indexOf('value_approve') === -1" id="value_approve">
          <span>{{ !competitor.assignments[round.id].approved ? 'no' : 'yes' }}</span>
        </span>
      </transition>
      <span @click.stop v-show="editing === 'APPROVE'">
        <br>
        <div @click="toggleApprove" class="btn-sm"
             :class="competitor.assignments[round.id].approved?'btn-outline-danger':'btn-outline-success'">
          {{ competitor.assignments[round.id].approved ? 'remove' : 'approve' }}
        </div>
      </span>
    </td>
  
  </tr>
</template>

<script>
import {validate} from 'vee-validate';
import {BsAlert} from "../../../mixins/swal_mixins";
import moment from "moment";

export default {
  name: "ManageCompetitorRow",
  props: ['competitor', 'tournament', 'round'],
  data() {
    return {
      editing: '',
      loading: [],
      baleSelection: {},
    }
  },
  
  computed: {
    requestedTime() {
      let index = this.competitor.line_time;
      if (index == null) return '';
      let time = this.tournament.line_times.find((t) => parseInt(t.id) === parseInt(index));
      if (time) return time.time;
      return '';
    },
    validFormHeaders() {
      let list = [];
      if (this.isRound) return list;
      for (let detail of this.tournament.regform) {
        if (detail.enabled) {
          let options = ['name', 'email', 'first_name', 'last_name'];
          if (!(options.includes(detail.option) || ['header', 'waiver'].includes(detail.type))) {
            list.push(detail);
          }
        }
      }
      return list;
    },
    
    available() {
      if (this.tournament.available.hasOwnProperty(this.round.id)) {
        return this.tournament.available;
      }
      return null;
    },
    baleOptions() {
      let assigned = this.competitor.assignments[this.round.id];
      if (assigned.line_time && assigned.location && this.available !== null) {
        let round = this.available[this.round.id];
        if (round[assigned.line_time] && round[assigned.line_time][assigned.location]) {
          let bales = round[assigned.line_time][assigned.location];
          if (bales) {
            delete bales.restrictions;
            return Object.keys(bales)
          }
        }
      }
      return [];
    },
    positionOptions() {
      let assigned = this.competitor.assignments[this.round.id];
      if (assigned.line_time && assigned.location && assigned.bale && this.available !== null) {
        let round = this.available[this.round.id];
        if (round[assigned.line_time] && round[assigned.line_time][assigned.location]) {
          if (round[assigned.line_time][assigned.location][assigned.bale]) {
            return round[assigned.line_time][assigned.location][assigned.bale];
          }
        }
      }
      return [];
    },
    isRound() {
      return Object.keys(this.round).length !== 0;
    },
    competitorDetail: {
      get() {
        let details = {};
        this.tournament.regform.forEach(function (detail) {
          if (detail.type !== 'header' && detail.option !== 'name' && detail.option !== 'email') {
            details[detail.option] = detail.multiple ? [] : '';
          }
        });
        this.competitor.regform.forEach(function (detail) {
          if (detail.type === 'image' && !detail.enabled) {
            detail.name = '/img/imagePending.jpg';
          }
          details[detail.option] = detail.multiple ? detail.multiple : detail.name;
          
        });
        return details;
      }
    }
  },
  methods: {
    createdAt(competitor) {
      let date = new Date(competitor.created_at);
      let momentDate = moment(date);
      return momentDate.format('D MMM YYYY h:mm A');
    },
    pricingExtras(extra) {
      if (this.competitor.pricing_extras && this.competitor.pricing_extras.length) {
        let exists = [];
        if (extra.hasOwnProperty('id')) {
          exists = this.competitor.pricing_extras.filter((e) => e.id === extra.id);
        } else {
          exists = this.competitor.pricing_extras.filter((e) => e.name === extra.name);
        }
        if (exists.length > 0) return 'yes';
      }
      return 'no';
    },
    showImage(url) {
      BsAlert.fire({
        html: url ? `<img src="${url}" class="mx-auto" width="400" height="400">` : 'No Image',
        showConfirmButton: false,
        showCloseButton: true,
        animation: false,
        showCancelButton: false,
      })
    },
    deleteCompetitor() {
      BsAlert.fire({
        titleText: `Delete ${this.competitor.name}?`,
        icon: 'error',
        html: 'This action cannot be undone. It will completely remove the competitor. ' +
          'A safe reversible alternative is to withdraw them, or to unapprove them from each round.',
        confirmButtonText: 'Yes, Delete Competitor',
        confirmButtonColor: '#f27474',
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.delete(`/tournaments/${this.tournament.slug}/competitors/${this.competitor.id}`)
              .then(() => {
                this.$emit('delete');
              })
        }
      })
    },
    getLineTimeFromId(id) {
      let timeObject = this.round['line_times'].find(t => t.id === parseInt(id));
      return timeObject?.time
    },
    editOption(detail) {
      if (this.editing === detail) {
        this.editing = '';
      } else {
        this.editing = detail;
      }
    },
    isSelected(detail, option) {
      let form = this.competitor.regform;
      let competitorSelected = form.find(function (details) {
        return details.option === detail.option;
      });
      
      if (competitorSelected) {
        if (detail.type === 'checkbox') {
          if (competitorSelected.multiple.indexOf(option) !== -1) {
            return true;
          }
        }
        if (competitorSelected.name === option) {
          return true;
        }
      }
      return false
    },
    handleAssignmentChange(detail) {
      let assignment = this.competitor.assignments[this.round['id']];
      assignment.location = detail === 'line_time' ? null : assignment.location;
      assignment.bale = ['line_time', 'location'].includes(detail) ? null : assignment.bale;
      assignment.position = ['line_time', 'location', 'bale'].includes(detail) ? null : assignment.position;
    },
    updateCompetitor(detail) {
      this.clearEditing();
      this.loading.push('value_' + detail);
      let details = {manage: true};
      if (['line_time', 'location', 'bale', 'position', 'approve'].includes(detail)) {
        this.handleAssignmentChange(detail);
        details['assignment'] = this.competitor.assignments[this.round['id']]
      } else if (['first_name', 'last_name', 'email', 'target', 'requested_line_time'].includes(detail)) {
        details['competitor'] = this.competitor
      } else {
        details['regform'] = this.competitorDetail
      }
      this.$axios.put(`/tournaments/${this.tournament.slug}/competitors/${this.competitor.uuid}`, details)
          .then(({data}) => {
            let index = this.loading.indexOf('value_' + detail);
            if (details.hasOwnProperty('assignment')) {
              this.$emit('update-available', data.available)
            }
            this.removeLoader(index);
            this.$emit('competitor-change');
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    toggleApprove() {
      if (this.competitor.assignments[this.round.id]) {
        let approved = this.competitor.assignments[this.round.id].approved;
        this.competitor.assignments[this.round.id].approved = !approved;
      } else {
        let newGroup = {
          'tournament_id': this.tournament.id,
          'round_id': this.round.id,
          'competitor_id': this.competitor.id,
          'location': null,
          'line_time': null,
          'bale': null,
          'position': null,
          'approved': 1,
        };
        this.competitor.assignments.push(newGroup);
        
      }
      
      this.updateCompetitor('approve');
    },
    clearEditing() {
      this.editing = '';
    },
    removeLoader(index) {
      this.loading.splice(index, 1)
    },
    getTargetName() {
      let target_id = this.competitor.target_id;
      let target = this.tournament.targets.find(f => parseInt(f.id) === parseInt(target_id));
      if (!target) {
        target = this.tournament.targets[0];
      }
      return target.name
    },
    withdrewClicked() {
      let url = '/tournaments/' + this.tournament.slug + '/competitors/' + this.competitor.uuid + '/status';
      BsAlert.fire({
        titleText: 'Approve ' + this.competitor.name,
        html: 'Do you wish to re-approve this competitor?',
        confirmButtonText: 'Approve',
        showLoaderOnConfirm: true,
        preConfirm: (message) => {
          return this.$axios.post(url, {
            'action': 'approve',
          })
                     .then(response => {
                       if (!response.data.success) {
                         throw new Error(response.statusText)
                       }
                       this.competitor.withdrew = null;
                       this.competitor.withdraw_request = null;
                       return BsAlert.fire({
                         title: 'Approved!',
                         showCancelButton: false,
                         html: this.competitor.name + ' has been approved.',
                         icon: 'success'
                       });
                     })
                     .catch(error => {
                       BsAlert.showValidationMessage(
                         `Request failed: ${error}`
                       )
                     })
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      });
    },
    unpaidClicked() {
      let url = '/tournaments/' + this.tournament.slug + '/competitors/' + this.competitor.uuid + '/status';
      BsAlert.fire({
        titleText: 'Remove payment from ' + this.competitor.name + '?',
        html: 'This will mark them as unpaid and send them the following message.',
        input: 'textarea',
        inputValidator: (value) => {
          return validate(value, 'clean').then(({valid, errors}) => {
            if (valid) {
              return;
            }
            if (errors.length) {
              return errors.join(' ');
            }
          });
        },
      }).then(({dismiss, value}) => {
        if (!dismiss) {
          this.$axios.post(url, {
            'action': 'unpaid',
            'message': value,
          })
              .then(response => {
                if (!response.data.success) {
                  throw new Error(response.statusText)
                }
                this.competitor.paid = 0;
                return BsAlert.fire({
                  title: 'Removed Payment!',
                  html: this.competitor.name + ' has been marked as unpaid.',
                  icon: 'success',
                  showCancelButton: false,
                })
              })
              .catch(error => {
                BsAlert.showValidationMessage(
                  `Request failed: ${error}`
                )
              })
        }
      });
    },
    paidClicked() {
      let url = '/tournaments/' + this.tournament.slug + '/competitors/' + this.competitor.uuid + '/status';
      BsAlert.fire({
        titleText: 'Mark ' + this.competitor.name + ' as paid',
        html: 'Reason for marking paid?',
        input: 'select',
        inputOptions: {
          'paid': 'Offline payment was made',
          'staff': 'Competitor is a Staff member',
          'comped': 'Fee has been waived',
          'custom': 'Competitor paid in full with custom price',
        },
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose something!'
          }
        },
        confirmButtonText: 'Approve',
        showLoaderOnConfirm: true,
        preConfirm: (selected) => {
          if (selected === 'custom') {
            return BsAlert.fire({
              html: 'Custom Price',
              input: 'text',
              showCancelButton: false,
              inputValidator: (value) => {
                if (!value) {
                  return 'You need to add a price!'
                }
              },
            }).then(({value}) => {
              return this.$axios.post(url, {
                'action': 'paid',
                'reason': selected,
                'amount': value,
              })
                         .then(response => {
                           if (!response.data.success) {
                             throw new Error(response.statusText)
                           }
                           this.competitor.paid = 1;
                           this.competitor.paid_reason = 'custom: ' + value;
                           return BsAlert.fire({
                             title: 'Paid!',
                             html: this.competitor.name + ' has been marked as paid.',
                             icon: 'success',
                             showCancelButton: false,
                           })
                         })
                         .catch(error => {
                           BsAlert.showValidationMessage(
                             `Request failed: ${error}`
                           )
                         })
            })
          } else {
            return this.$axios.post(url, {
              'action': 'paid',
              'reason': selected
            })
                       .then(response => {
                         if (!response.data.success) {
                           throw new Error(response.statusText)
                         }
                         this.competitor.paid = 1;
                         return BsAlert.fire({
                           title: 'Paid!',
                           html: this.competitor.name + ' has been marked as paid.',
                           icon: 'success'
                         });
                       })
                       .catch(error => {
                         BsAlert.showValidationMessage(
                           `Request failed: ${error}`
                         )
                       })
          }
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      });
    },
    waitlistedClicked() {
      let url = '/tournaments/' + this.tournament.slug + '/competitors/' + this.competitor.uuid + '/status';
      BsAlert.fire({
        titleText: 'Remove ' + this.competitor.name + ' from waitlist?',
        showLoaderOnConfirm: true,
        preConfirm: (selected) => {
          return this.$axios.post(url, {
            'action': 'unwaitlist',
          })
                     .then(response => {
                       if (!response.data.success) {
                         throw new Error(response.statusText)
                       }
                       this.competitor.wait_list = null;
                       return BsAlert.fire({
                         title: 'Success!',
                         html: this.competitor.name + ' has been taken off the waitlist.',
                         icon: 'success'
                       })
                     })
                     .catch(error => {
                       BsAlert.showValidationMessage(
                         `Request failed: ${error}`
                       )
                     })
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      });
    }
  }
}
</script>

<style scoped>

.fade-leave-active, .fade-enter-active {
  transition: background-color 3s;
}

.fade-enter-from, .fade-leave-to, .fade-leave /* .fade-leave-active below version 2.1.8 */
{
  @apply bg-blue-600;
  
}

</style>
