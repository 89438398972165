<template>
  <div>
    <div v-if="isManager" style="width:300px;">
      <label class="btn btn-outline-blue-600 w-full">
        <input
          @change="importClicked"
          type="file"
          id="roundAssignments"
          name="registrations"
          accept="text/csv"
          class="hidden">
        <i class="fas fa-file-upload mr-2"></i> Import From CSV
      </label>
      <a :href="`/tournaments/${tournament.slug}/assignment_template/${round.id}`"
         class="btn btn-outline-blue-600 block"
         download>
        <i class="fas fa-download mr-2"></i> Download CSV Template
      </a>
    </div>
    <div v-else></div>
  </div>
</template>

<script>

import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "AssignmentsImport",
  props: ['tournament', 'round'],
  computed: {
    isManager() {
      return this.user && ((this.user.hasOwnProperty('auth_manage') && this.user.auth_manage));
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  methods: {
    async handleImport(formData) {
      let url = `/tournaments/${this.tournament.slug}/roundAssignments`;
      await this.$axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status === 200) {
          return axios.get(`/tournaments/${this.tournament.slug}/suite/tournament`)
          .then(({data}) => {
            this.$store.commit('tournament/SET_TOURNAMENT', {
              'tournament': data.tournament
            });
          })
        }
      }).catch(({response}) => {
        console.log(response);
        BsAlert.fire({
          titleText: 'Process Failed',
          html: 'Please check the format of the CSV file and try again.',
          icon: 'error',
          showCancelButton: false,
        });
      });
    },
    importClicked() {
      let formData = new FormData();
      let csv = document.querySelector('#roundAssignments');
      if (!csv.files.length) {
        return BsAlert.fire({title: 'No file has been selected. Please try again.', showCancelButton: false});
      }
      formData.append("roundAssignments", csv.files[0]);
      formData.append('roundId', this.round.id);
      this.importPopup(formData);
    },
    downloadCsv() {
    
    },
    importPopup(formData) {
      BsAlert.fire({
        titleText: 'Update round assignments?',
        html: 'This will remove all previous assignments for the round "' + this.round.name + '".',
        icon: 'warning',
        showLoaderOnConfirm: true,
        preConfirm: () => this.handleImport(formData),
      }).then(value => {
        if (value.value) {
          BsAlert.fire({title: 'CSV assignment import complete!', showCancelButton: false});
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
