import {defineStore} from "pinia";

export const useDisplayStore = defineStore('display', {
  state: () => {
    return {
      completion: {rounds: {}},
      end: 1,
      hideShown: true,
      incCompetitor: null,
      lineTime: null,
      location: null,
      locked: false,
      notAccepted: [],
      restrictedBales: [],
      restrictedCompetitors: [],
      restrictedLocations: [],
      restrictedRounds: [],
      restrictedTimes: [],
      restrictedLadders: [],
      round: null,
      tournamentSlug: null,
      shownBales: [],
      competitor: null,
      arrow: null,
      replace: [],
      target: null,
      activeUser: null,
      verified: false,
      showLock: false,
      localTimes: [],
      localRounds: [],
      localLocations: [],
      localBales: [],
      localCompetitors: [],
      localLadders: [],
      weekCount: 2,
      restrictedRegTimes: [],
      restrictedDetail: [],
      timeWaitList: false,
      ladder: null,
      step: 0,
      localExtra: {},
      showHamburgerDropdown: false,
      scoreOnline: false,
      usedCode: false,
    };
  },
  getters: {},
  actions: {
    toggleOpen() {
      this.showHamburgerDropdown = !this.showHamburgerDropdown;
    },
    closeHamburger() {
      this.showHamburgerDropdown = false;
    },
    setNotAccepted(score) {
      let listCopy = this.notAccepted;
      let index = listCopy.indexOf(score);
      if (index !== -1) {
        delete listCopy[index];
      } else {
        listCopy.push(score);
      }
      this.notAccepted = listCopy;
    },
    clearCompetitorNotAccepted(competitorId) {
      let listCopy = this.notAccepted;
      listCopy = listCopy.filter(f => f.competitor_id !== competitorId);
      this.notAccepted = listCopy;
    },
  },
})