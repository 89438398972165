<template>
  <div class="flex flex-wrap">
    <div v-for="(formDetail,index) in tournament.regform" class="w-full mt-2 px-3 mb-2"
         :key="formDetail.name + '_' + index"
         :class="formDetail.type === 'header' ? '' : 'md:w-1/2 md:mt-0'">
      <div v-if="formDetail.enabled && !restricted.includes(formDetail.id)">
        <template v-if="formDetail.type === 'header'">
          <h3 class="mt-3 text-2xl">{{ formDetail.name }}
            <small class="ml-2 text-gray-600 text-sm">{{ formDetail.description }}</small>
          </h3>
          <hr class="mt-0">
        </template>
        <div v-else class="flex flex-col">
          <div class="flex justify-between">
            <label :for="formDetail.option" class="text-xl">{{ tournament.regform[index].name }} </label>
            <div class="text-red-600 text-sm flex justify-center flex-col">
              {{ formDetail.required ? 'Required' : '' }}
            </div>
          </div>
          <div v-if="formDetail.type === 'radio'" class="flex flex-col ml-4">
            <div class="text-sm text-gray-600">{{ formDetail.description }}</div>
            <div v-for="option in formDetail.multiple">
              <Field
                v-model="competitor.form[formDetail.option].name"
                v-if="competitor.form[formDetail.option]"
                :rules="formDetail.required ? 'required|clean' : 'clean'"
                :type="formDetail.type"
                :name="formDetail.option"
                :value="option.label"
                @change="setRestricted(competitor.form[formDetail.option])"
                class="form-check-input"/>
              <label class="form-check-label ml-1">{{ option.label }} </label>
              <ErrorMessage class="errorText" :name="formDetail.option"/>
            </div>
          </div>
          <div v-else-if="formDetail.type === 'checkbox'" class="flex flex-col ml-4">
            <div class="text-sm text-gray-600">{{ formDetail.description }}</div>
            <div v-for="(option,i) in formDetail.multiple">
              <Field
                v-if="competitor.form[formDetail.option] "
                v-model="competitor.form[formDetail.option].multiple"
                :rules="formDetail.required ? 'required' : ''"
                :value="option.label"
                :type="formDetail.type"
                :name="formDetail.option + '[]'"
                @change="setRestricted(competitor.form[formDetail.option])"
                class="form-check-input"/>
              <label class="form-check-label ml-1">{{ option.label }} </label>
              <ErrorMessage class="errorText" :name="formDetail.option + '[]'"/>
            </div>
          </div>
          <div v-else-if="formDetail.type === 'selection'" class="flex flex-col">
            <div class="text-sm text-gray-600">{{ formDetail.description }}</div>
            <Field
              as="select"
              v-if="competitor.form[formDetail.option]"
              v-model="competitor.form[formDetail.option].name"
              :rules="formDetail.required ? 'required' : ''"
              :key="index + '_selection'"
              :name="formDetail.option"
              @change="setRestricted(competitor.form[formDetail.option])"
              class="bs-form-box">
              <option disabled selected hidden>Select One...</option>
              <option v-for="option in formDetail.multiple" :value="option.label">
                {{ option.label }}
              </option>
            </Field>
            <ErrorMessage class="errorText" :name="formDetail.option"/>
          </div>
          <div v-else-if="formDetail.type === 'textarea'" class="flex-col">
            <Field
              as="textarea"
              v-if="competitor.form[formDetail.option]"
              v-model="competitor.form[formDetail.option].name"
              :rules="formDetail.required ? 'required|min:2|max:200|clean' : 'min:2|max:200|clean'"
              :placeholder="formDetail.placeholder ? formDetail.placeholder : ''"
              :name="formDetail.option"
              class="form-control">
              {{ competitor && competitor.form[formDetail.option] ? competitor.form[formDetail.option].name : '' }}
            </Field>
            <div class="ml-2 text-sm text-gray-600">{{ formDetail.description }}</div>
            <ErrorMessage class="errorText" :name="formDetail.option"/>
          </div>
          <div v-else-if="formDetail.type === 'image'" class="flex-col">
            <div class="ml-2 text-sm text-gray-600">{{ formDetail.description }}</div>
            <image-upload
              :inc_url="competitor.form[formDetail.option].name"
              :inc_enabled="competitor.form[formDetail.option].enabled"
              :option="competitor.form[formDetail.option].option"
              :type="'competitor'"
              :file_id="tournament.id"
              @update_image="updateImage"
              @remove_image="removeImage"/>
            <input type="hidden" :name="formDetail.option" v-model="competitor.form[formDetail.option].name">
            <input type="hidden" :name="`${formDetail.option}__enabled`"
                   v-model="competitor.form[formDetail.option].enabled">
          </div>
          <div
            v-else-if="formDetail.type === 'waiver' && competitor && competitor.form[formDetail.option] && competitor.form[formDetail.option].multiple"
            class="flex flex-col ml-4">
            <div class="text-sm text-gray-600">{{ formDetail.description }}</div>
            <div
              class="border rounded border-gray-500 p-2 whitespace-pre-line min-h-0 max-h-xs overflow-y-auto"
              style="font-family: 'Roboto', sans-serif;">
              <pre class="whitespace-normal">{{ formDetail.placeholder }}</pre>
            </div>
            <div>
              <Field
                v-model="competitor.form[formDetail.option].multiple[0].selected"
                :rules="formDetail.required ? 'required' : ''"
                type="checkbox"
                :name="formDetail.option"
                value="accepted"/>
              <label>I have read and understand the waiver. I agree to be bound by its terms.</label>
              <ErrorMessage class="errorText" :name="formDetail.option"/>
            </div>
          
          </div>
          <div v-else-if="formDetail.type === 'date'">
            <div class="bs-form-box w-full flex justify-end p-0">
              <flat-pickr
                class="w-full px-3 focus:outline-none"
                :config="dateConfig"
                v-model="competitor.form[formDetail.option].name"
                :name="formDetail.option"
                :ref="`picker_${formDetail.id}`"
              />
              <i @click="openPicker(formDetail)" class="far fa-calendar text-xl p-2"></i>
            </div>
          </div>
          <div v-else class="">
            <Field
              v-if="formDetail.option === 'name'"
              rules="required|max:25|min:2|clean"
              v-model="competitor.name"
              :type="formDetail.type"
              :name="formDetail.option"
              :placeholder="formDetail.placeholder"
              class="bs-form-box w-full"/>
            <Field
              v-else-if="formDetail.option === 'first_name'"
              rules="required|max:25|min:2|clean"
              v-model="competitor.first_name"
              :type="formDetail.type"
              :name="formDetail.option"
              :placeholder="formDetail.placeholder"
              class="bs-form-box w-full"/>
            <Field
              v-else-if="formDetail.option === 'last_name'"
              rules="required|max:25|min:2|clean"
              v-model="competitor.last_name"
              :type="formDetail.type"
              :name="formDetail.option"
              :placeholder="formDetail.placeholder"
              class="bs-form-box w-full"/>
            <Field
              v-else-if="formDetail.option === 'email'"
              rules="required|max:40|min:2|clean|email"
              v-model="competitor.email"
              :type="formDetail.type"
              :name="formDetail.option"
              :placeholder="formDetail.placeholder"
              class="bs-form-box w-full"/>
            <Field
              v-else
              v-if="competitor.form[formDetail.option]"
              :rules="formDetail.required ? 'required|min:2|max:100|clean' : 'min:2|max:100|clean'"
              v-model="competitor.form[formDetail.option].name"
              :placeholder="formDetail.placeholder"
              :type="formDetail.type"
              :name="formDetail.option"
              class="bs-form-box w-full"/>
            <ErrorMessage class="errorText" :name="formDetail.option"/>
            <small v-if="(formDetail.option === 'first_name' || formDetail.option === 'name') && !competitor.id"
                   class="flex-col">
              You should only register yourself here. To register other archers please
              <a href="#/group" class="text-blue-500">register as a group.</a></small>
            <small class="ml-2 text-gray-600">{{ formDetail.description }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";
import ImageUpload from "../partials/ImageUpload.vue";
import flatPickr from "vue-flatpickr-component";
import {Form, ErrorMessage, Field} from "vee-validate";

export default {
  name: "CompetitorCreateEdit",
  components: {Form, ErrorMessage, Field, flatPickr, ImageUpload},
  props: ['tournament', 'user', 'inc_competitor', 'restrictions'],
  data() {
    return {
      competitor: this.inc_competitor,
      restricted: null,
      showUpload: null,
      dateConfig: {
        dateFormat: 'd M Y',
        allowInput: true,
        static: false,
      },
      store: useDisplayStore(),
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'restrictedRegTimes',
      'restrictedDetail'
    ]),
    validFormDetails() {
      let list = [];
      for (let option in this.tournament.regform) {
      
      }
    }
  },
  methods: {
    openPicker(detail) {
      let picker = this.$refs[`picker_${detail.id}`][0].fp;
      picker.open();
    },
    updateImage(details) {
      let [url, enabled, option] = details;
      this.competitor.form[option].name = url;
      this.competitor.form[option].enabled = enabled;
    },
    removeImage(option) {
      this.competitor.form[option].name = '';
    },
    updateTimeOptions() {
      let vm = this;
      this.$axios.post('/tournaments/' + this.tournament.slug + '/available_times',
        {data: this.competitor.form},
      )
      .then(({data}) => {
        if (!data.success) return;
        if (data.times !== null) {
          vm.restrictedRegTimes = data.times;
        }
        if (data.detailedTimes !== null) {
          vm.restrictedDetail = data.detailedTimes;
        }
      }).catch((error) => {
        this.store.$patch({
          restrictedRegTimes: [],
          restrictedDetail: [],
        })
      });
    },
    setRestricted(newValue) {
      let list = [];
      this.updateTimeOptions();
      if (Object.values(this.competitor.form).length && this.restrictions.length) {
        this.restrictions.forEach((restriction) => {
          let field = this.tournament.regform.find(f => f.id === restriction.regform_id);
          let name = field.multiple.find(f => f.id === restriction.multiple_id);
          if (this.competitor.form[field['option']].name === name.label) {
            list.push(restriction.type_id);
          }
        })
      }
      this.restricted = list;
    },
  },
  created() {
    let vm = this;
    this.tournament.regform.forEach(function (f) {
      if (f.option !== 'first_name' && f.option !== 'last_name' && f.option !== 'email' && f.option !== 'header') {
        if (!Object.keys(vm.competitor.form).includes(f.option)) {
          debugger;
          vm.competitor.form[f.option] = {...f};
          if (f.type === 'text' || f.type === 'textarea') {
            vm.competitor.form[f.option].name = '';
          } else if (f.type === 'image') {
            vm.competitor.form[f.option].name = '';
          }
          if (vm.user && vm.user.archer && vm.user.archer.registration_defaults) {
            let found = vm.user.archer.registration_defaults.find(d => d.option === f.name);
            if (found) {
              if (f.multiple) {
                let exists = vm.competitor.form[f.option].multiple.find(m => m.label === found.value);
                if (exists)
                  vm.competitor.form[f.option].name = found.value;
              } else {
                vm.competitor.form[f.option].name = found.value;
              }
            }
          }
        } else if (f.type === 'checkbox') {
          let selected = vm.competitor.form[f.option].multiple;
          f.multiple.forEach(function (m) {
            m.selected = selected.includes(m.label);
          });
        }
      }
    });
    this.restrictedDetail = null;
    this.setRestricted();
  }
}
</script>

<style scoped>

</style>
