<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {ErrorMessage, Field} from "vee-validate";
import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";

const store = useCompetitorStore();

const invalid = (option) => {
  if (!store.submitted) return false;
  if (option.type === 'waiver') return !store.waiverIsSigned(option);
  for (let archer of store.selectedArchers) {
    if (store.groupValues[archer.id][option.id] == null) {
      if (option.required) return true;
    }
  }
  return false;
};
</script>

<template>
  <div id="groupFormCards" class="scroll-mt-4">
    <div v-for="option of store.regform">
      <div class="bs-card" :class="invalid(option) ? 'invalid' : ''"
           :id="option.type === 'waiver' ? 'waiverCard' : `${option.name}Card`">
        <div class="bs-section-header">
          {{ option.name }} <span v-if="!option.required" class="text-stone-400 ml-1">(optional)</span>
        </div>
        <div v-if="option.type === 'waiver'">
          <button @click.prevent="store.viewWaiverTapped(option)" class="m-4 mb-8">
            <i class="fa fa-file mr-1"></i> View the waiver
          </button>
          <FormCheckbox
            :disabled="true"
            :active="store.waiverIsSigned(option)"
            :title="`I acknowledge that I have read and understand the waiver. I am registering on behalf of
                    ${ store.archerNames}, they have agreed to be bound by its terms.`"/>
        </div>
        <div v-else>
          <div v-for="archer of store.selectedArchers" class="mb-2">
            <div class="flex">
              <div class="bs-input-box mr-4 flex-2 flex justify-center flex-col text-stone-600">{{ archer.name }}</div>
              <div v-if="store.disabled(archer, option)" class="bs-input-box flex-2 disabled">
                Disabled
              </div>
              <Field v-else-if="(['text','date','textarea']).includes(option.type)"
                     :name="`${archer.name} (Archer ID: ${archer.id}) ${option.name}`"
                     class="bs-input-box flex-2"
                     :rules="`${option.required ? 'required' : ''}|clean`"
                     v-model="store.groupValues[archer.id][option.id]"/>
              <Field as="select" v-else-if="option.type === 'radio' || option.type === 'selection'"
                     :name="`${archer.name} (Archer ID: ${archer.id}) ${option.name}`"
                     :rules="option.required ? 'required' : ''"
                     class="bs-input-box flex-2"
                     @change="store.updateTimeOptions(archer, option)"
                     v-model="store.groupValues[archer.id][option.id]">
                <option v-for="multiple of option.multiple" :value="multiple.label"
                        :selected="store.groupValues[archer.id][option.id] === multiple.label"> {{ multiple.label }}
                </option>
              </Field>
              <div v-else>The type {{ option.type }} is not yet supported</div>
              <div v-if="store.optionHasPriceRestrictions(option)" class="flex-1 ml-4 bs-input-box disabled">
                <div class="flex justify-between">
                  <div class="flex flex-col justify-center">
                    <i class="fa fa-lg fa-dollar-sign"></i>
                  </div>
                  <div v-if="store.groupValues[archer.id][option.id] != null" class="text-black">
                    {{ store.priceForOption(option, archer) }}.00
                  </div>
                  <div v-else class="text-black">{{ store.tournamentPrice }}.00</div>
                </div>
              </div>
            </div>
            <ErrorMessage :name="`${archer.name} (Archer ID: ${archer.id}) ${option.name}`" class="errorText"/>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid {
  border-color: red;
}

.disabled {
  @apply bg-stone-100 text-stone-400 flex justify-center flex-col;
  height: 37px;
}

.disabledPrice {
  @apply bg-stone-100 text-stone-400 flex justify-center flex-col;
  height: 37px;
}

</style>