<template>
  <div class="bs-right-margin">
    <div class="flex justify-between pb-1">
      <h1 class="text-3xl"> Tournaments </h1>
      <div class="flex flex-col justify-center">
        <new-tournament-button :user="user" :color="'gray-600'"/>
      </div>
    </div>
    <hr class="mt-0">
    <div class="flex flex-col-reverse sm:flex-row mb-2 justify-center">
      <div class="flex flex-wrap w-full sm:w-1/2 justify-start sm:justify-end sm:mt-0">
        <div class="h-center mr-2 mt-2">
          <button v-if="!showFilters" class="outlineButton" @click.prevent="showFilters = !showFilters">
            filters <i class="fas fa-xs fa-plus"></i>
          </button>
          <button v-else-if="showClear" class="outlineButton" @click.prevent="clearAll()">
            clear filters
          </button>
          <button v-else class="outlineButton" @click.prevent="showFilters = !showFilters">
            hide filters
          </button>
        </div>
        <template v-if="showFilters">
          <div class="h-center mr-2 mt-2">
            <div class="btn-group">
              <div v-if="selectedTime" @click="clearTime" class="btn btn-outline-secondary">X</div>
              <dropdown width="180px">
                <template v-slot:trigger>
                  <button type="button" class="btn btn-secondary dropdown-toggle whitespace-nowrap">
                    Status{{ selectedTime ? ': ' + selectedTime : '' }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="time in ['Upcoming','Active','Ended']" class="dropdown-item" href=""
                   @click.prevent="timeSelected(time)">{{ time }}</a>
              </dropdown>
            </div>
          </div>
          
          <div class="h-center mr-2 mt-2">
            <div class="btn-group">
              <div v-if="selectedSport" @click="clearSport" class="btn btn-outline-secondary">X</div>
              <dropdown>
                <template v-slot:trigger>
                  <button type="button" class="btn btn-secondary dropdown-toggle whitespace-nowrap">
                    Sport{{ selectedSport ? ': ' + selectedSport : '' }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="(sport, index) in sports_list" class="dropdown-item" href=""
                   @click.prevent="sportSelected(index, sport)">{{ sport }}</a>
              </dropdown>
            </div>
          </div>
        
        </template>
      </div>
      <div class="w-full sm:w-1/2">
        <div class="bs-form-box p-0 max-w-md mx-auto sm:mx-0 mt-2 flex rounded-lg">
          <div @click="applyFilters" class="flex flex-col justify-center text-gray-500 p-2 mr-2">
            <i class=" fas fa-search"></i>
          </div>
          <input class="flex-grow focus:outline-none mr-2" :placeholder="' Find Tournaments'" v-model="search">
          <button v-if="search.length > 0" @click="clearSearch"
                  class="flex flex-col justify-center text-gray-500 p-2 mr-2 font-light">
            <i class=" fas fa-times"></i>
          </button>
          <div v-else>&nbsp;</div>
        </div>
      </div>
    </div>
    <div>
      <tournament-list-item v-for="tournament in tournaments" :key="tournament.id" :tournament="tournament"/>
      <div v-if="!tournaments.length">
        <p>No tournaments found. Try adjusting your filters or search term.</p>
      </div>
    
    </div>
  </div>
</template>

<script>
import TournamentListItem from "./TournamentListItem.vue";
import NewTournamentButton from "../partials/NewTournamentButton.vue";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "TournamentList",
  components: {NewTournamentButton, TournamentListItem},
  props: ['inc_tournaments', 'user', 'sports_list', 'inc_filters'],
  data() {
    return {
      showFilters: true,
      selectedTime: this.inc_filters['status'] ? this.inc_filters['status'] : '',
      selectedSport: '',
      tournaments: this.inc_tournaments,
      filters: {time: this.selectedTime},
      search: ''
    }
  },
  watch: {
    search(text) {
      if (!text.length || text.length > 2) {
        this.applyFilters();
      }
    }
  },
  computed: {
    showClear() {
      return this.filters && Object.values(this.filters).length > 0;
    }
  },
  methods: {
    clearAll() {
      this.showFilters = !this.showFilters;
      this.filters = {};
      this.selectedTime = '';
      this.selectedSport = '';
      this.applyFilters();
    },
    timeSelected(time) {
      this.selectedTime = time;
      this.filters['time'] = time;
      this.applyFilters();
    },
    clearTime() {
      this.selectedTime = null;
      delete this.filters['time'];
      this.applyFilters();
    },
    sportSelected(index, sport) {
      this.selectedSport = sport;
      this.filters['sport'] = index;
      this.applyFilters();
    },
    clearSport() {
      this.selectedSport = null;
      delete this.filters['sport'];
      this.applyFilters();
    },
    applyFilters() {
      this.$axios.get('/tournaments', {
        params: {
          'filters': this.filters,
          'search': this.search,
          'json': true,
        }
      }).then(({data}) => {
        if (data['error']) {
          BsAlert.fire({title: data['error'], showCancelButton: false})
        } else {
          this.tournaments = data['tournaments'] ?? [];
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    clearSearch() {
      this.search = '';
      this.applyFilters();
    }
  }
}
</script>

<style scoped>
.outlineButton {
  @apply btn btn-outline-gray-600;
}
</style>
