<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {computed, onMounted, ref, watch} from 'vue';
import axios from "axios";
import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";

const store = useCompetitorStore();
const invalid = computed(() => store.submitted && !store.justTimeComplete)
const available = ref([]);

const timeCounts = ref({});
watch(() => store.customValues, (value) => {
    updateTimeOptions();
  }, {deep: true}
)
watch(() => store.gender, (value) => {
  updateTimeOptions();
})

function updateTimeOptions() {
  if (Object.keys(timeCounts.value).length === 0) setTimeCounts();
  let data = JSON.parse(JSON.stringify(store.customValues));
  data['gender'] = store.gender;
  axios.post(`/tournaments/${store.tournamentSlug}/available_times`, {data: data}).then(({data}) => {
    if (data.success) {
      available.value = data.detailedTimes;
    }
  });
};

function disabledTime(time) {
  let enabled = times.value.includes(`${time.id}`);
  return !enabled;
}

const times = computed(() => {
  let list = [];
  for (let timeId of Object.keys(available.value)) {
    let added = false;
    let item = available.value[timeId];
    if (!('locations' in item)) {
      if (item.restrictions === false) {
        list.push(timeId);
        continue;
      }
    }
    for (let l in item.locations) {
      let loc = item.locations[l];
      if (loc.allowed === false) break;
      for (let b in loc.bales) {
        let bale = item.locations[l].bales[b];
        if (bale.allowed === true) {
          let spots = Object.keys(timeCounts.value).length ? timeCounts.value[timeId][loc.location][parseInt(b) + 1] : 0;
          if (spots > 0) {
            list.push(timeId);
            added = true;
            break;
          }
        }
      }
      if (added) break;
    }
  }
  return list;
})

function setTimeCounts() {
  axios.post(`/tournaments/${store.tournamentSlug}/available_counts`).then(({data}) => {
    if (data.success) {
      timeCounts.value = data.data;
    }
  });
}

const hasFullTime = computed(() => {
  for (let time of store.times) {
    let disabled = disabledTime(time);
    if (disabled) return true;
  }
  return false;
});
onMounted(() => {
  setTimeCounts();
})
</script>

<template>
  <div v-if="store.times.length > 1" class="bs-card" id="timeCard" :class="invalid ? 'invalid' : ''">
    <div class="bs-section-header">Line Time</div>
    <div class="flex flex-wrap">
      <div v-for="time in store.times" class="mb-2 mr-2" :key="`${time.id}_time_item`">
        <button @click.prevent="store.toggleTime(time)"
                :disabled="disabledTime(time)"
                class="bs-input-box"
                :class=" `${disabledTime(time) ? 'bg-stone-100 cursor-not-allowed':''} ${store.line_time === time.id ? 'bg-black text-white' : ''}`">
          {{ time.time }}{{ time.memo != null ? ` (${time.memo})` : '' }}
        </button>
      </div>
    
    </div>
    <div v-if="hasFullTime && store.line_time === null">
      <form-checkbox
        @tapped="store.toggleWaitlist()"
        :active="store.wait_list"
        title="Waitlist"
        :subTitle="`One or more line times are either full, or not allowed for your registration selection. Check this box to be added to the waitlist.`"/>
    </div>
  </div>
</template>

<style scoped>
.invalid {
  border-color: red;
}
</style>