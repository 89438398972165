<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {Field} from "vee-validate";
import {computed, onMounted, ref, nextTick} from "vue";
import {loadStripe} from "@stripe/stripe-js";
import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";
import {BsAlert} from "../../../mixins/swal_mixins";

const store = useCompetitorStore();

const props = defineProps(['stripeKey'])
const stripePromise = store.clubTakesPayments ? loadStripe(props.stripeKey) : null;


const elements = ref(null);
const card = ref(null);
const stripe = ref(null);

async function handleStripe() {
  return await stripe.value.createToken(card.value).then(async ({token, error}) => {
    if (error) {
      await BsAlert.fire({
        title: 'Unable to process payment',
        icon: 'error',
        html: error.message,
        showCancelButton: false
      });
    } else {
      store.stripeToken = token;
      await store.submitForm();
    }
  })
};

const validCard = () => {
  const cardElementContainer = document.querySelector('#card-element');
  let complete = cardElementContainer?.classList.contains('StripeElement--complete') ?? false;
  let invalid = cardElementContainer?.classList.contains('StripeElement--invalid') ?? false;
  return complete && !invalid;
};

async function registerTapped() {
  store.loading = true;
  await nextTick();
  if (!store.groupTimesComplete) {
    await store.showIncomplete();
  } else if (waitlist) {
    await store.submitForm();
  } else if (!store.payOffline && store.clubTakesPayments) {
    if (validCard()) {
      await handleStripe();
    } else {
      store.submitted = true;
    }
  } else {
    await store.submitForm();
  }
}

onMounted(() => {
  if (props.stripeKey != null) {
    loadStripe(props.stripeKey).then((value) => {
      stripe.value = value;
      elements.value = stripe.value.elements();
      card.value = elements.value.create('card');
      card.value.mount('#card-element');
    })
  }
})

const paymentComplete = () => {
  if (store.clubTakesPayments) {
    if (validCard()) return true;
  }
  return store.payOffline;
};

const invalid = computed(() => {
  return store.submitted && !paymentComplete();
});

const waitlist = computed(() => {
  return store.waitlist.length === store.selectedArchers.length;
})
</script>

<template>
  <div>
    <div v-show="!waitlist" class="bs-card" :class="invalid ? 'invalid' : ''">
      <div class="bs-card-header">Payment Information</div>
      <div>
        <div v-show="!store.payOffline" id="card-element">
        
        </div>
        <FormCheckbox :active="store.payOffline"
                      v-if="store.allowOffline"
                      class=" mt-8"
                      @change="store.payOffline = !store.payOffline"
                      sub-title="By checking this box, you are agreeing to pay the amount outlined above prior to competing in the tournament."
                      title="Make payment offline"/>
      
      </div>
      <!-- //TODO: add this if I can figure out how to style my own stripe form-->
      <!-- <div v-if="false" class="flex">-->
      <!--        <div class="flex-4">-->
      <!--          <label for="card number" class="bs-section-header">Card number</label>-->
      <!--          <Field name="card number" class="bs-input-box"/>-->
      <!--        </div>-->
      <!--        <div class="flex-2 mx-4">-->
      <!--          <label for="expiration date" class="bs-section-header">Expiration date</label>-->
      <!--          <Field name="expiration date" class="bs-input-box"/>-->
      <!--        </div>-->
      <!--        <div class="flex-1">-->
      <!--          <label for="cvv" class="bs-section-header">CVV</label>-->
      <!--          <Field name="cvv" class="bs-input-box"/>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="flex justify-between">
      <div v-if="store.clubTakesPayments || store.payOffline">
        <button @click.prevent="registerTapped" class="btn btn-black ml-2" :disabled="store.loading">
          Register <span v-if="store.loading" class="fa fa-spinner fa-spin"></span>
        </button>
      </div>
      <div>
        <div v-if="store.error" class="errorText">
          <span v-html="store.error"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid {
  border-color: red;
}
</style>