<template>
  <div v-if="tournament" class="flex justify-center my-2">
    <div>
      <h1 class="text-3xl">Payment</h1>
      <hr>
      <div class="flex flex-col">
        <div> Tournament: {{ tournament.name }}</div>
        <div> Competitor: {{ competitor.name }}</div>
        <div v-if="competitor.partial_paid">
          Remaining Price<i @click.prevent="breakdown = !breakdown" class="fal fa-question-circle ml-1"></i>
          : ${{ remainingPrice }}.00
        </div>
        <div v-else>
          Price
          <i class="fal fa-question-circle ml-1" @click.prevent="breakdown = !breakdown"
             v-if="competitor.pricing_extras && competitor.pricing_extras.length"></i>
          : ${{ fullAmount }}.00
        </div>
        <div v-show="breakdown" class="p-2 border border-blue-600 rounded-lg">
          <table>
            <tbody>
            <tr>
              <td></td>
              <td class="text-right">${{ tournament.payments.price + tournament.payments.processing_fee }}.00</td>
              <td>Base Price</td>
            </tr>
            <tr v-if="competitor.pricing_extras && competitor.pricing_extras.length"
                v-for="option in competitor.pricing_extras">
              <td>{{ option.type }}</td>
              <td class="text-right">${{ option.amount }}.00</td>
              <td>{{ option.name }}</td>
            </tr>
            <tr v-if="competitor.partial_paid">
              <td>-</td>
              <td class="text-right">${{ downPaid }}.00</td>
              <td>Down Payment</td>
            </tr>
            <tr style="border-top:1px solid;">
              <td></td>
              <td class="text-right">${{ remainingPrice }}.00</td>
              <td>Remaining</td>
            </tr>
            </tbody>
          
          </table>
        </div>
        <div class="my-2 w-72 sm:w-96">
          <div v-if="useAuthNet">
            <div class="bs-form-box" id="authNetCard">
              <input class="w-1/3 focus:outline-none" maxlength="16" type="text" name="cardNumber"
                     id="cardNumber" placeholder="Card number"/>
              <div class="text-right float-right w-2/3">
                <input class="w-1/4 justify-end text-right focus:outline-none" type="text"
                       name="expMonth" id="expMonth" maxlength="2" placeholder="MM"/>{{ ' / ' }}
                <input class="w-1/4 justify-end focus:outline-none" maxlength="4" type="text"
                       name="expYear" id="expYear" placeholder="YYYY"/>
                <input class="w-1/4 justify-end text-right focus:outline-none" maxlength="4" type="text"
                       name="cardCode" id="cardCode" placeholder="CVV"/>
              </div>
            </div>
            <input type="hidden" name="authNetToken" id="authNetToken"/>
            <input type="hidden" name="authNetDescriptor" id="authNetDescriptor"/>
          </div>
          <div v-else>
            <div ref="card" class="bs-form-box flex flex-col justify-center"></div>
          </div>
        </div>
        <div class="flex justify-end">
          <div class="btn btn-outline-blue-600" @click.prevent="pay">
            Pay <span v-if="loading"><i class="fas fa-spin fa-spinner"></i></span>
          </div>
        </div>
      
      </div>
    </div>
  
  </div>
</template>

<script>
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "CompetitorInvoice",
  props: ['competitor', 'api_login'],
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      tournament: this.competitor.tournament,
      loading: false,
      breakdown: false,
    }
  },
  computed: {
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
    fullAmount() {
      let price = this.tournament.payments.price + this.tournament.payments.processing_fee;
      if (this.competitor.pricing_extras) {
        this.competitor.pricing_extras.forEach(extra => {
          if (extra.type === '+') {
            price += parseInt(extra.amount)
          } else {
            price -= parseInt(extra.amount)
          }
        });
      }
      return price;
    },
    downPaid() {
      let processing = this.competitor.paid_processing ? this.tournament.payments.processing_fee : 0;
      return (this.competitor.partial_paid + processing);
    },
    remainingPrice() {
      return this.fullAmount - this.downPaid;
    },
    
  },
  methods: {
    async handleStripe() {
      if (!this.stripe) {
        return BsAlert.fire({
          title: 'Unknown payment form error, please refresh the page and try again.',
          showCancelButton: false,
        })
      }
      return this.stripe.createToken(this.card).then(({token, error}) => {
        if (error) {
          throw new Error(error.message);
        }
        axios.post('/competitor_pay/' + this.competitor.uuid, {stripeToken: token.id})
             .then(({data}) => {
               if (data['success']) {
                 BsAlert.fire({title: 'Payment Accepted', showCancelButton: false, icon: 'success'}).then(() => {
                   window.location.href = '/tournaments/' + this.tournament.slug + '#/competitors/' + this.competitor.uuid;
                 });
               } else if (data['error']) {
                 BsAlert.fire({title: data['error'].message, showCancelButton: false, icon: "error"});
               }
               this.loading = false;
             });
      }).catch(function (error) {
        throw new Error(error.message);
      });
      
    },
    
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        var html = '<div>'
        Object.values(response.messages.message).forEach((message) => {
          html += `<div>${message.text}</div>`;
        })
        html += '</div>'
        BsAlert.fire({
          title: 'Error Processing',
          html: html,
          icon: "error",
          showCancelButton: false,
        })
        this.loading = false;
      } else {
        document.getElementById("authNetCard").innerHTML = '';
        axios.post('/competitor_pay/' + this.competitor.uuid, {
          authNetToken: response.opaqueData.dataValue,
          authNetDescriptor: response.opaqueData.dataDescriptor,
        }).then(({data}) => {
          if (data['success']) {
            BsAlert.fire({title: 'Payment Accepted', showCancelButton: false, icon: "success"}).then(() => {
              window.location.href = '/tournaments/' + this.tournament.slug + '#/competitors/' + this.competitor.uuid;
            });
          } else if (data['error']) {
            BsAlert.fire({title: data['error'].message, showCancelButton: false, icon: "error"});
          }
          this.loading = false;
        });
      }
    },
    handleAuthNet() {
      var data = {
        authData: {clientKey: this.tournament.club.auth_net_key, apiLoginID: this.api_login},
        cardData: {
          cardNumber: document.getElementById("cardNumber").value,
          month: document.getElementById("expMonth").value,
          year: document.getElementById("expYear").value,
          cardCode: document.getElementById("cardCode").value,
        }
      }
      
      Accept.dispatchData(data, this.responseHandler);
    },
    pay() {
      this.loading = true;
      if (this.useAuthNet) {
        this.handleAuthNet();
      } else {
        this.handleStripe().catch((error) => {
          this.loading = false;
          BsAlert.fire({title: error.message, showCancelButton: false, icon: "error"});
        })
      }
      
    }
  },
  mounted() {
    if (!this.useAuthNet) {
      if (typeof (Stripe) == 'undefined') return;
      this.stripe = Stripe(this.tournament.club.stripe.stripe_publishable_key);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount(this.$refs['card']);
    }
  },
}
</script>

<style scoped>

</style>
