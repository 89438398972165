<script setup>
import {useResultsStore} from "../../../stores/ResultsStore";
import {onBeforeMount} from "vue";
import {useRoute} from "vue-router"

const props = defineProps(['tournament']);
const store = useResultsStore();
const route = useRoute();

onBeforeMount(() => selectFilters());

const selectFilters = () => {
  let query = route.query;
  if (query.round) {
    props.tournament.rounds.forEach((round) => {
      if (round.slug === query.round) {
        roundSelected(round, false);
      }
    });
  }
  for (let form of Object.values(store.filterList)) {
    if (query[form.option]) {
      form.multiple.forEach((option) => {
        if (option.label === query[form.option]) {
          store.filterList[form.option].selected = option;
        }
      });
    }
  }
  if (query.team) {
    store.selectedTeam = query.team;
  }
  if (query.competitor) {
    let competitor = props.tournament.competitorList[query.competitor];
    store.selectedCompetitor = competitor;
  }
  if (query.search) {
    store.search = query.search;
  }
}

const cancel = (item) => {
  store.filterList[item.option]['selected'] = null;
  store.updateUrl();
}

const select = (form, option, update) => {
  store.filterList[form.option]['selected'] = option;
  if (update) store.updateUrl();
}
const cancelTeam = () => {
  store.selectedTeam = null;
  store.updateUrl();
};

const overallSelected = (update = true) => {
  store.overall = true;
  store.round = {};
  store.title = `${props.tournament.name} Overall Results`;
  if (update) store.updateUrl();
};
const roundSelected = (round, update = true) => {
  store.overall = false;
  store.title = `${round.name} Results`;
  store.round = round;
  store.selectedRound = round;
  if (update) store.updateUrl();
};
const teamSelected = (team, update) => {
  store.selectedTeam = team;
  if (update) store.updateUrl();
  if (store.overall) {
    overallSelected();
  } else {
    roundSelected(store.round);
  }
};
</script>
<template>
  <div class="bg-blue-100 shadow-inner">
    <div class="flex flex-col">
      <div class="mx-auto mt-2 w-full md:w-3/4 lg:w-1/2 max-w-lg bs-form-box flex">
        <span class="h-center"><i class="fas fa-search"></i></span>
        <input type="text" class="flex-grow pl-2 mx-2" v-model="store.search" @blur="store.updateUrl()" id="searchBar">
        <div v-if="store.search !== ''" class="h-center" @click="store.search = ''">
          <span class=""><i class="fas fa-times"></i></span>
        </div>
      </div>
      <div class="flex justify-center flex-wrap">
        <div>
          <div @click='overallSelected' class="btn-sm m-2" :class="store.overall?'btn-blue-600':'btn-outline-blue-600'">
            Overall
          </div>
        </div>
        <dropdown v-if="tournament.rounds.length > 1" class="m-2">
          <template v-slot:trigger>
            <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
              Round{{ store.overall ? '' : ': ' + store.round.name }} <i class="fas fa-caret-down"></i>
            </button>
          </template>
          <a v-for="round in tournament.rounds" class="dropdown-item" href=""
             @click.prevent="roundSelected(round, true)">
            {{ round.name }}
          </a>
        </dropdown>
        <div v-else>
          <div
            @click='roundSelected(tournament.rounds[0])' class="btn-sm m-2"
            :class="store.overall?'btn-outline-blue-600':'btn-blue-600'">
            Breakdown
          </div>
        </div>
        <div v-for="form of store.filterList">
          <div class="btn-group m-2">
            <div v-if="form.selected != null" @click="cancel(form)" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown>
              <template v-slot:trigger>
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  {{ form.name }}{{ form.selected != null ? ': ' + form.selected.label : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="option in form.multiple" class="dropdown-item" href=""
                 @click.prevent="select(form, option, true)">{{ option.label }}</a>
            </dropdown>
          </div>
        </div>
        <div v-if="store.teams && store.teams.length">
          <div class="btn-group m-2">
            <div v-if="store.selectedTeam" @click="cancelTeam" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown width="180px">
              <template v-slot:trigger>
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  Team/Club{{ store.selectedTeam ? ': ' + store.selectedTeam : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="team in store.teams" class="dropdown-item" href="" @click.prevent="teamSelected(team, true)">
                {{ team }}
              </a>
            </dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>


</style>